.remove-btn {
    margin-right: 0.5rem;
}

.fade-enter {
    opacity: 0.01;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-in;
}

/* body{
    cursor: pointer;
    
} */
  .header-color{
    background: #314670;  /* fallback for old browsers */
  
  }
  
  .landing-grid{
  background: -webkit-linear-gradient(to right, #FCFCFC, #FCFCFC);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,#FCFCFC, #FCFCFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: relative ;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
  }
  .btn-dark {
    background-color: #314670;
    border-color: #314670;
  }
  .btn-dark:hover , .btn-dark:active, .btn-dark:focus {
    background-color: #314670;
    border-color: #314670;
  }
  .btn-dark:not(:disabled):not(.disabled):active {
    background-color: #314670;
    border-color: #314670;
  }
  .projects-grid {
    display: inline-flex;
  }
  
  .mdl-progress > .progressbar {
    background-color: whitesmoke;
  }
  .page-link {
    color: #314670;
  }
  a {
    color: #314670;
  }

  a:hover {
    color: #fff;
  }

  
  .mdl-button {
    color: #fff;
  }

  .mdl-button:hover {
    background-color: #314670;
  }
  .page-item.active .page-link {
    background-color: #314670;
    border-color: #314670;
  }
  /* Resume Page css */
  .resume-right-col {
    background: #314670;
    color: white;
    padding: 2em;
  }
  
  /* contact page css */
  .contact-right-col {
    background: #314670;
    color: #fff;
    padding: 1rem;
    min-height: 32rem;
    max-height: 50rem;
  }
  .contact-body {
    margin: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: 2em;
  }
  
  .contact-grid {
    text-align: center;
    justify-content: center;
    width: 80%;
    height: 550px;
    background: white;
    color: black;
    margin-bottom: 20px;
  }
  
  .mdl-card__menu.blog-dialog-close-button.mdl-close-button{
    float: left;
  }
  .contact-grid h2 {
    font-family: 'Anton', sans-serif;
  }
  
  .contact-grid p {
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
  }
  
  .contact-grid hr {
    border-top: 5px dotted black;
    width: 50%;
    margin: auto;
  }
  
  .contact-list i {
    font-size: 35px;
    padding-right: 3rem;
    text-align: center;
  }
  
  .contact-list{
   padding: 0px 0px 0px 100px; 
  }
  
  
  /* contact page css */
  
  .avatar-img {
    height: 250px;
    padding-top: 5em;
    border-radius: 50%;
  }
  
  .banner-text {
    background-color: #314670;
    width: 75%;
    margin: auto;
    border-radius: 10px;
  }
  
  .banner-text h1 {
    font-size: 66px;
    font-weight: bold;
    color: white;
  }
  
  .banner-text hr {
    border-top: 5px dotted white;
    width: 50%;
    margin: auto;
  }
  
  .banner-text p {
    color: white;
    font-size: 20px;
    padding: 0.5rem;
  }

  .h1-homepage-heading {
    margin: 1.1rem 0 ;
  }
  
  .social-links {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
  }

  .social-links a {
    margin: 1rem 0;
    color: #fff;
  }
  
  .social-links i {
    color: white;
    font-size: 4em;
    padding: 5px;
  }

  
  /*Resume */
  
  .resume-left-section-border {
    border-top: 3px solid #833fb2;
    width: 50%;
  }
  .about-section-border {
    border-top: 3px solid #833fb2;
    width: 50%;
    float: left;
  }
  .heading-page-name{
    padding: 0 32px;
  }
  .hr-section-border {
    border-top: 3px solid #833fb2;
    width: 50%;
    margin: 0 32px 20px;
  }
  .homepage-navigation-border {
    border-top: 3px solid #833fb2;
  }

  .resume-left-contact-section-border {
    border-top: 3px solid #833fb2;
    width: 100%;
  }

.contact-list-item {
  font-size: 2.5rem ;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


  .resume-right-section-border {
    border-top: 3px solid #833fb2;
  }

  /*About me */
  .about-img{
    height: 330px;
  
  }
  .about-details hr{
    width :50%;
    margin: auto;
  }
  
  /* Footer*/
  
  .footer-header{

    background: #fff;
  
  }
  
  /*resume cards */
  
  .cards-grid{
    min-width: auto;
    margin: auto;
    margin-bottom: 10px;
  }
  
  /*Blogs */
  
  .close-button {
    background: #314670;
    
  }
  .close-button:hover{
    background: #314670;
  }
  .close-button:active{
    background: #314670;
  }
  .close-button:focus{
    background: #314670 !important;
  }
  .remove-btn-blog-post{
     float: right;
     margin: 0;
     min-width: 3rem;
     background-color: #314670;
  }
  .edit-btn-blog-post{
    float: right;
  }
 
  .alert-danger {
    color: #fff;
    background-color: #314670;
    border-color: #314670;
  }
  .open-dialog{
    width: 50%;
    top: 10px !important;
    overflow: scroll;
    height: 100%;
    overflow-x: hidden;
  }

  .blog-read-me-button{
    background: #314670;
    text-decoration: underline;
  }
  .project-link-button {
    background: #314670;
    text-decoration: underline;
  }
  .project-link-button a{
    color: #fff;
  }
  .error-page{
    text-align: center;
    margin: auto;
  }
  .error-page img{
    width: auto;
  
  }
  .blogs-contents-image{
    width: 70%;
  
     left: 50%;
     right: 50%;
     margin-left: 30px;
     margin-right: 30px;
  
  }
  .card-title-image{
    color:#fff;
     height:200px;
     width: auto;
      background:url("https://res.cloudinary.com/tammycloudinary/image/upload/c_thumb,w_200,g_face/v1548273942/Blog-intro.jpg") center;
  }
  .card-blog-title-image-text h2.mdl-card__title-text {
    font-weight: bold;
  }

  .card-blog-title-image {
    color:#fff;
    height:176px;
    font-weight: bold;
    width: auto;
    background:url("https://res.cloudinary.com/tammycloudinary/image/upload/v1587355745/blog-684748_1920.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }
  /*blog contents */
  
  .dialog-contents p{
    font-size: 15px;
    font-style: italic;
  
  }
  /* .mdl-grid {
    margin: auto;
    width: 100vw;
  } */
  .bold-font{
    font-weight: bold;
  }
  .mdl-card__title{
    padding: 5px 16px !important;
  }
  .mdl-card__supporting-text {
    width: 100% ;
  }

  .mdl-layout__header-row .mdl-navigation__link .nav-link {
      color: white;
      padding: .25rem;
  }


  .mdl-layout__drawer .mdl-navigation {
      padding: 0;
  }

  .mdl-layout__drawer .mdl-navigation .mdl-navigation__link .nav-link {
    color: #757575;
    padding: 0;
  }

  .social-links-grid{
    text-align: right;
    margin: 1rem;
    font-weight: bold;
  }

  .fa-icon-contact {
    padding-right: 10px;
  }
  .contact-a-link-color {
    color: #0056b3;
  }

  .contact-a-link-color:hover {
    text-decoration: none;
    color: #fff;
  }
  .container-dashboard {
    height: 30rem;
    display: contents;
  }
  .container-dashboard-row {
    margin: 4rem;
    border: 1px solid black;
    padding: 3rem 0;
  }

  .h4-resume-institute-name {
    margin: 0 0 1rem;
  }

  .blog-data-text {
    padding: 0 1rem;
  }
  .h4-blog-data-text {
    margin: 1rem 0;
  }
  .mdl-mini-footer{
    background: -webkit-linear-gradient(to right, #FCFCFC, #FCFCFC);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,#FCFCFC, #FCFCFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 1rem ;
    color: gray;
  }
  
  .footer-section-type-left{
    margin: 5px;
  }
 
  .mdl-layout__inner-container {
    display: block;
  }

  .mdl-layout__content{
    display: flex;
  }
  .loading-container-spinner{
    margin: 25vh 0;
  }
  .container {
    max-width: inherit;
    padding: 0;
    margin: o;
  }
  .pagination{
    margin: 1rem;
  }
  .contact-a-link-color{
    color : #fff;
  }
  .blog-data-share-social-cardmenu {
    left: 16px;
  }


  @media only screen and ( max-width: 450px) {
    .heading-page-name{
      padding: 25px 10px 0;
    }
    .hr-section-border {
      margin: 0 10px;
    }
    .social-links i {
      color: white;
      font-size: 3em;
      padding: 5px;
    }

    .homepage-languages{
      margin: 0;
    }
    .social-links{
      display: unset;
      width: auto;
    }
    .h4-blog-data-text {
      margin: 1rem 0;
    }
    .blog-data-text {
      padding: 0 1rem;
    }
    .container-dashboard {
      height: 40rem;

    }
    .fa-icon-contact{
      padding-right: 2px;
    }

    .social-links-grid{
      margin: 0;
      padding: 0;
    }

    .projects-grid {
      display: flex;
    }
    .blog-grid-cell{
      display: flex;
    }
    .navbar-toggler{
      display: none;
    }
    .collapse:not(.show){
      display: block;
    }
    .open-dialog{
      width: auto !important;
    }
    .banner-text{
      width: auto;
      text-align: center;
    }
    .banner-text h1{
      font-size: 4rem !important;
    }
  
    .contact-grid {
      text-align: center;
      justify-content: center;
      width: 100%;
      background: white;
      height: auto;
    }
    .contact-list {
      padding: 0px 0px 0px 20px;
    }
    .mdl-list__item-primary-content{
      font-size: 1.4rem !important;
    }
    .error-page img{
      width: 100%;
    }

    .loading-container{
      height: 100vh;
    }

  }